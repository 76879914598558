exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-api-tsx": () => import("./../../../src/pages/api.tsx" /* webpackChunkName: "component---src-pages-api-tsx" */),
  "component---src-pages-bogholder-revisor-tsx": () => import("./../../../src/pages/bogholder_revisor.tsx" /* webpackChunkName: "component---src-pages-bogholder-revisor-tsx" */),
  "component---src-pages-book-tsx": () => import("./../../../src/pages/book.tsx" /* webpackChunkName: "component---src-pages-book-tsx" */),
  "component---src-pages-designtest-tsx": () => import("./../../../src/pages/designtest.tsx" /* webpackChunkName: "component---src-pages-designtest-tsx" */),
  "component---src-pages-driftsselskab-tsx": () => import("./../../../src/pages/driftsselskab.tsx" /* webpackChunkName: "component---src-pages-driftsselskab-tsx" */),
  "component---src-pages-enkeltmands-tsx": () => import("./../../../src/pages/enkeltmands.tsx" /* webpackChunkName: "component---src-pages-enkeltmands-tsx" */),
  "component---src-pages-guide-tsx": () => import("./../../../src/pages/guide.tsx" /* webpackChunkName: "component---src-pages-guide-tsx" */),
  "component---src-pages-holdingselskab-tsx": () => import("./../../../src/pages/holdingselskab.tsx" /* webpackChunkName: "component---src-pages-holdingselskab-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-kontakt-tsx": () => import("./../../../src/pages/kontakt.tsx" /* webpackChunkName: "component---src-pages-kontakt-tsx" */),
  "component---src-pages-lukkonto-tsx": () => import("./../../../src/pages/lukkonto.tsx" /* webpackChunkName: "component---src-pages-lukkonto-tsx" */),
  "component---src-pages-priser-tsx": () => import("./../../../src/pages/priser.tsx" /* webpackChunkName: "component---src-pages-priser-tsx" */),
  "component---src-pages-privacy-tsx": () => import("./../../../src/pages/privacy.tsx" /* webpackChunkName: "component---src-pages-privacy-tsx" */),
  "component---src-pages-regnskabsevent-tsx": () => import("./../../../src/pages/regnskabsevent.tsx" /* webpackChunkName: "component---src-pages-regnskabsevent-tsx" */),
  "component---src-pages-userterms-tsx": () => import("./../../../src/pages/userterms.tsx" /* webpackChunkName: "component---src-pages-userterms-tsx" */)
}

